import React from 'react'

import './section.styles.scss';
import HeaderSection from '../HeaderSection/HeaderSection.component';

export default function Section({ children, className, id, heading }) {
    return (
        <div className={`section ${className}`} id={`${id}`}>
            {heading ? 
                (<HeaderSection
                    title={heading.title}
                    subtitle={heading.subtitle}
                />)
            : 
                null
            }
            {children}
        </div>
    )
}
