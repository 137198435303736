import React from 'react'

import './card.styles.scss';

export default function Card({ icon, title, content, className}) {
    return (
        <div className={`card ${className}`}>
            <img src={icon} alt="" className="icon"/>
            <h3>{title}</h3>
            <p>{content}</p>
        </div>
    )
}
