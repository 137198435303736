import React from "react";

import "./features.styles.scss";

import Card from "../../snippets/Card/Card.component";

// Icons
import LightningFastIcon from "./icons/lightning-fast.svg";
import SupportIcon from "./icons/support.svg";
import DetailedGuidesIcon from "./icons/elearning.svg";
import GroupBuysIcon from "./icons/group-money.svg";
import FreeAutoCheckoutIcon from "./icons/tools-and-utensils.svg";
import EarlyInfoIcon from "./icons/reminder.svg";
import RewardSystem from "./icons/trophy.svg";
import PartnerShips from "./icons/agreement.svg";
import BotRestocks from "./icons/code-window.svg";

import Section from "../../snippets/Section/Section.component";

export default function Features() {
  let sectionProps = {
    heading: {
      title: "Our Features",
      subtitle: "Take a look at what we have to offer.",
    },
    className: "features",
    id: "features",
  };
  return (
    <Section {...sectionProps}>
      <div className="grid features">
        <Card
          icon={LightningFastIcon}
          title={"Lightning Fast Monitors"}
          content={
            "Members have access to the fastest monitors in the game. Never miss out on another drop or restock again. Our monitors cover a variety of sites including Shopify, Supreme, Nike, Footsites, and much more!"
          }
        />
        <Card
          icon={SupportIcon}
          title={"1 on 1 Support"}
          content={
            "Our team is always ready to assist you with any questions you have. Whether you are just starting out or an experienced reseller, our team will always guide in the right path to make sure you are prepared for any release."
          }
        />
        <Card
          icon={DetailedGuidesIcon}
          title={"Detailed Guides"}
          content={
            "We provide comprehensive guides on everything you need to know to be successful in the reselling game. Covering everything from how to get started to perfecting your skills."
          }
        />
        <Card
          className={"groupbuy"}
          icon={GroupBuysIcon}
          title={"Exclusive Groupbuys"}
          content={
            "Increase your collection. Members are given the opportunity to acquire some of the most sought after reselling tools. We make sure you are equipped with the best of the best to ensure success."
          }
        />
        <Card
          icon={FreeAutoCheckoutIcon}
          title={"Free Autocheckout"}
          content={
            "Members are granted access to Free Autocheckout across all drops ran by our expert staff. Slots are only ran on the top bots for the most success possible."
          }
        />
        <Card
          icon={EarlyInfoIcon}
          title={"Early Information"}
          content={
            "With Defiance you will be notified of any big drops happening that you didn’t even know about. We also provide in-depth release guides including sitelists, setups, and much more for every drop."
          }
        />
        <Card
          icon={RewardSystem}
          title={"Reward System"}
          content={
            "As members continue to post success, help out other members, and show appreciation, they are rewarded with some sweet prizes such as membership discounts and much more."
          }
        />
        <Card
          icon={PartnerShips}
          title={"Partnerships"}
          content={
            "We are partnered with some of the top companies and providers in the game. This allows members to get a chance to secure desired tools and even purchase some at discounted prices."
          }
        />
        <Card
          icon={BotRestocks}
          title={"Early Links"}
          content={
            "Alongside our in-depth release guides, members have access to exclusive and accurate early links for numerous sites, allowing you to checkout faster than everyone else, every drop."
          }
        />
      </div>
    </Section>
  );
}
