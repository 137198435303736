import React, { Component } from "react";
import StripeCheckout from "react-stripe-checkout";

class StripeButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonText: "Sold out - $45",
      soldOut: true,
    };
  }
  componentDidMount = () => {
    const search = new URLSearchParams(document.location.search);
    fetch(
      `https://dashboard.defiance.group/api/dropin/stock?password=${search.get(
        "password"
      )}`
    ).then((res) => {
      res.json().then((data) => {
        console.log(data);
        if (data.inStock) {
          this.setState({
            soldOut: !data.inStock,
            buttonText: !data.inStock ? "Sold out - $45" : "Purchase - $45",
          });
        }
      });
    });
  };
  onToken = async (token) => {
    window.authcordSubmitting = true;
    this.setState({
      buttonText: "Processing...",
    });

    const config = {
      stripeApiKey: "pk_live_dleRSP4oHW0XL3K51UecAXGb00bLy93xP9",
      inStockText: "Purchase Now",
      soldOutText: "Sold Out",
      // baseURL: "http://localhost:8080",
      baseURL: "https://dashboard.defiance.group",
      price: 5000,
      currency: "usd",
      name: "Defiance",
    };

    const search = new URLSearchParams(document.location.search);
    const request = await fetch(config.baseURL + "/api/dropin/purchase", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        email: token.email,
        token: token.id,
        password: search.get("password") || "landingpage",
      }),
    }).then((result) => result.json());

    window.authcordSubmitting = false;

    this.setState({
      buttonText: "Sold out - $45",
    });

    if (request.success) {
      document.location.href =
        config.baseURL +
        `/success?license_key=${request.license_key}&name=${request.name}`;
    } else {
      document.location.href = config.baseURL + "/sorry";
    }
  };

  render() {
    return (
      <StripeCheckout
        token={this.onToken}
        stripeKey="pk_live_dleRSP4oHW0XL3K51UecAXGb00bLy93xP9"
        amount={0}
        currency="USD"
        name="Defiance"
        description="Defiance Membership ($45 / Month)"
        panelLabel="Pay $45.00"
        disabled={this.state.soldOut}
      >
        <button className={`btn ${this.state.soldOut ? "soldOut-d" : ""}`}>
          {this.state.buttonText}
        </button>
      </StripeCheckout>
    );
  }
}

export default StripeButton;
