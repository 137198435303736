import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';

import './header-section.styles.scss';

export default function HeaderSection({ title, subtitle }) {
    return (
        <div className="header-section">
            <ScrollAnimation animateIn="fadeInDown" animateOnce>
                <h2>{title}</h2>
                <p>{subtitle}</p>
            </ScrollAnimation>
        </div>
    )
}
