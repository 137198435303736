import React, { Component } from 'react'

import '../main.scss';
import "animate.css/animate.min.css";

import Header from '../../components/sections/Header/Header.component';
import Features from '../../components/sections/Features/Features.component';
import Success from '../../components/sections/Success/Success.component';
import FAQ from '../../components/sections/FAQ/FAQ.component';
import Contact from '../../components/sections/Contact/Contact.component';
import Footer from '../../components/sections/Footer/Footer.component';

export default class Index extends Component {
    render() {
        return (
            <React.Fragment>
                <Header />
                <Features />
                <Success />
                <FAQ />
                <Contact />
                <Footer />
            </React.Fragment>
        )
    }
}
