import React from 'react'

import DefianceLogo from './icons/DefianceLogo.png';
import Instagram from './icons/instagram.svg';
import Twitter from './icons/twitter.svg';

import './footer.styles.scss';

export default function Footer() {
    return (
        <footer>
            <div className="contain">
                <img src={DefianceLogo} alt="" className="logo-icon"/>
                <p className="copyright">&copy; 2020 Defiance LLC. All Rights Reserved.</p>
                <div className="socials">
                    <a href="https://www.instagram.com/defianceio" target="_blank" rel="noopener noreferrer" className="social-icon">
                        <img src={Instagram} alt=""/>
                    </a>
                    <a href="https://twitter.com/DefianceIO" target="_blank" rel="noopener noreferrer" className="social-icon">
                        <img src={Twitter} alt=""/>
                    </a>
                </div>
            </div>
        </footer>
    )
}
