import React from 'react';
import './pages/index/Index.page';
import Index from './pages/index/Index.page';

function App() {
  return (
    <Index />
  );
}

export default App;
