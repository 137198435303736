import React, { Component } from 'react'
import { motion } from "framer-motion"
import ScrollAnimation from 'react-animate-on-scroll';

import Card from '../../snippets/Card/Card.component';
import Section from '../../snippets/Section/Section.component';
import './success.styles.scss'
import DefianceLogo from './icons/DefianceLogo.png';

export default class Success extends Component {
    constructor(){
        super();
        this.state = {
            offsetL: 0, 
            // offsetR: 4261
            offsetR: 2000
        }
    }
    componentDidMount(){
        setInterval(() => {
            let { offsetL, offsetR } = this.state;
            this.setState({
                offsetL: offsetL + 1,
                offsetR: offsetR + 1
            })
        }, 5)
    }
    render() {
        let sectionProps = {
            className: 'success',
            id: 'success'
        };
        let { offsetL, offsetR } = this.state;
        return (
            <Section {...sectionProps}>
                <section>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce>
                        <Card
                            icon={DefianceLogo}
                            title={"Success"}
                            content={"Here at Defiance we aim to ensure that no member is left behind. Whether you are just starting out, or an expert in the field, we will make sure you push the boundaries and take your reselling career to the next level."}
                            className={"success-section"}
                        />
                    </ScrollAnimation>
                </section>
                <div className="contain">
                    <motion.div className="left-phones" style={{ backgroundPositionY: `-${offsetL}px`}}/>
                    <motion.div className="right-phones" style={{ backgroundPositionY: `${offsetR}px`}}/>
                </div>
            </Section>
        )
    }
}
