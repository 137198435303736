import React from "react";
import Section from "../../snippets/Section/Section.component";
import Card from "../../snippets/Card/Card.component";

import "./faq.styles.scss";

import DefianceCostIcon from "./icons/deftag.png";
import JoinIcon from "./icons/add.png";
import PaymentMethodIcon from "./icons/card.png";

export default function FAQ() {
  let sectionProps = {
    heading: {
      title: "Frequently Asked Questions",
      subtitle: "Find the answers to your questions here.",
    },
    className: "faq",
    id: "faq",
  };
  return (
    <Section {...sectionProps}>
      <div className="cards">
        <Card
          icon={DefianceCostIcon}
          title={"How much does Defiance cost?"}
          content={
            "Defiance currently costs $45 per month. Your card will be automatically charged every month and you can update your payment or cancel at any time."
          }
          className={"tag"}
        />
        <Card
          icon={JoinIcon}
          title={"How can I join?"}
          content={
            <>
              Follow us on{" "}
              <a
                href="https://twitter.com/DefianceIO"
                target="_blank"
                rel="noopener noreferrer"
              >
                Twitter
              </a>{" "}
              and{" "}
              <a
                href="https://www.instagram.com/defianceio"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>{" "}
              and wait for us to announce a restock. Don't forget to turn on
              notifications!
            </>
          }
          className={"add"}
        />
        <Card
          icon={PaymentMethodIcon}
          title={"What payment methods do you accept?"}
          content={
            "We accept all major credit cards as we utilize Stripe as our payment processor."
          }
          className={"master"}
        />
      </div>
    </Section>
  );
}
