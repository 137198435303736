import React, { Component } from 'react'
import ScrollAnimation from 'react-animate-on-scroll';

import './header.styles.scss';

import Nav from '../../snippets/Nav/Nav.component';
import StripeButton from '../../snippets/StripeButton/StripeButton.component'
import Logo from './logov2.png'


export default class Header extends Component {
    render() {
        return (
            <React.Fragment>
                <Nav />
                <header id='home'>
                    <section>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce>
                        <img src={Logo} alt="" className="logo"/>
                        <p>Defy the odds.</p>
                        <StripeButton />
                        </ScrollAnimation>
                    </section>
                </header>
            </React.Fragment>
        )
    }
}
