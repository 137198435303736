import React, { Component } from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll';

import './nav.styles.scss';

import DefianceLogo from './icons/DefianceLogo.png';

export default class Nav extends Component {
    constructor(props){
        super(props);
        this.state = {
            menu: false
        }
    }
    handleMenu = () => {
        let { menu } = this.state;
        this.setState({
            menu: !menu
        })
    }
    closeMenu = () => {
        this.setState({
            menu: false
        })
    }
    render() {
        let { menu } = this.state;
        return (
            <nav className={`${!menu ? 'close' : ''}`}>
                <button className={`menu ${!menu ? '' : 'close'}`} onClick={() => this.handleMenu()} />
                <a href="/"><img src={DefianceLogo} alt="" className="logo-icon"/></a>
                <ul className="links">
                    <li><AnchorLink href="#home" onClick={() => this.closeMenu()}>Home</AnchorLink></li>
                    <li><AnchorLink href="#features" onClick={() => this.closeMenu()}>Features</AnchorLink></li>
                    <li><AnchorLink href="#success" onClick={() => this.closeMenu()}>Success</AnchorLink></li>
                    <li><AnchorLink href="#faq" onClick={() => this.closeMenu()}>FAQ</AnchorLink></li>
                    <li><AnchorLink href="#contact" onClick={() => this.closeMenu()}>Contact</AnchorLink></li>
                </ul>
                <a href="https://dashboard.defiance.group/" className="btn">Dashboard</a>
            </nav>
        )
    }
}
