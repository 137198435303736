import React, { Component } from 'react'
import axios from 'axios';
import './contact.styles.scss'

import Section from '../../snippets/Section/Section.component'

export default class Contact extends Component {
    constructor(){
        super();
        this.state = {
            btnMsg: 'Send Message',
            submitting: false,
            name: '',
            email: '',
            message: ''
        };
    }
    onChange = e => {
        let name = e.target.name
        this.setState({
            [name]: e.target.value
        })
    }
    sendMessage = e => {
        e.preventDefault();
        let { name, email, message } = this.state;
        let body = { name, email, message };
        this.setState({
            btnMsg: 'Sending...',
            submitting: true
        })
        axios.post('https://us-central1-defiance-website.cloudfunctions.net/send-email', body)
            .then(res => {
                this.setState({
                    btnMsg: 'Message Sent!'
                }, () => {
                    setTimeout(() => {
                        this.setState({
                            btnMsg: 'Send Message',
                            submitting: false,
                            name: '',
                            message: '',
                            email: ''
                        })
                    }, 2000)
                })
            })
            .catch(err => {
                console.log('ERR: ', err);
                this.setState({
                    btnMsg: 'Error. Message not sent.'
                }, () => {
                    setTimeout(() => {
                        this.setState({
                            btnMsg: 'Send Message',
                            submitting: false
                        })
                    }, 2000)
                })
            })
    }
    render() {
        let sectionProps = {
            heading: {
                title: 'Contact Us',
                subtitle: 'We look forward to hearing from you.'
            },
            className: 'contact',
            id: 'contact'
        };
        let { name, email, message, submitting, btnMsg } = this.state;
        return (
            <Section {...sectionProps}>
                <div className="contact-box">
                    <form onSubmit={this.sendMessage}>
                        <label htmlFor="fullname">
                            <span>Full Name</span>
                            <input type="text" name="name" id="fullname" required={true} value={name} onChange={this.onChange} />
                        </label>
                        <label htmlFor="email">
                            <span>E-Mail</span>
                            <input type="email" name="email" id="email" required={true} value={email} onChange={this.onChange} />
                        </label>
                        <label htmlFor="message" id="textarea">
                            <span>Message</span>
                            <textarea name="message" id="message" required={true} value={message} onChange={this.onChange} />
                        </label>
                        <div className="row">
                            <button className={`btn ${submitting ? 'disabled' : ''}`} type="submit" disabled={submitting}>{btnMsg}</button>
                        </div>
                    </form>
                </div>
            </Section>
        )
    }
}
